// Custom elements
.container {
  width: 100%;
  display: block;
  margin: auto;
  padding: 0 15px;

  @media (min-width: 480px) {
    max-width: 480px;
  }

  @media (min-width: 576px) {
    max-width: 576px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 992px) {
    max-width: 992px;
  }

  @media (min-width: 1200px) {
    max-width: 1200px;
  }

  @media (min-width: 1600px) {
    max-width: 1600px;
  }
}

.ant-row-flex {
  margin-left: -15px;
  margin-right: -15px;
}
