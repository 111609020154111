/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/nunito-regular.eot"); /* IE9 Compat Modes */
  src: local("Nunito"), local("Nunito"),
    url("../../fonts/nunito-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/nunito-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../fonts/nunito-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../../fonts/nunito-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../../fonts/nunito-regular.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
/* nunito-700 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/nunito-700.eot"); /* IE9 Compat Modes */
  src: local("Nunito"), local("Nunito"),
    url("../../fonts/nunito-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/nunito-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../fonts/nunito-700.woff")
      format("woff"),
    /* Modern Browsers */ url("../../fonts/nunito-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../../fonts/nunito-700.svg#Nunito")
      format("svg"); /* Legacy iOS */
}
/* Vidaloka-regular - latin */
@font-face {
  font-family: "Vidaloka";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/vidaloka-regular.eot"); /* IE9 Compat Modes */
  src: local("Vidaloka"), local("Vidaloka"),
    url("../../fonts/vidaloka-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../../fonts/vidaloka-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../../fonts/vidaloka-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../../fonts/vidaloka-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../../fonts/vidaloka-regular.svg#Vidaloka")
      format("svg"); /* Legacy iOS */
}
