$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex !default;

@import "~bootstrap/scss/mixins/_breakpoints.scss";
@import "~bootstrap/scss/mixins/_image.scss";
@import "~bootstrap/scss/utilities/_spacing.scss";
@import "~bootstrap/scss/utilities/_sizing.scss";
@import "~bootstrap/scss/utilities/_display.scss";
@import "~bootstrap/scss/utilities/_flex.scss";

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
    .order#{$infix}-first {
      order: -1;
    }

    .order#{$infix}-last {
      order: 12 + 1;
    }

    @for $i from 0 through 12 {
      .order#{$infix}-#{$i} {
        order: $i;
      }
    }
  }
}

.img-fluid {
  @include img-fluid();
}
