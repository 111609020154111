.App {
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.Header {
  padding: 2rem 0;

  .logo {
    img {
      max-width: 160px;
      width: 100%;
      height: auto;
    }
  }

  .container {
    position: relative;
  }

  #menuToggle {
    position: absolute;
    top: 0;
    right: 15px;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  nav {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      a {
        margin-left: 0 !important;
        margin-bottom: 10px;
        width: 100%;
        text-align: center;
      }
    }
  }
}

.Footer {
  color: #ffffff;

  nav {
    a {
      font-weight: bold;
      color: #ffffff !important;

      @include media-breakpoint-down(sm) {
        margin-left: 0 !important;
        display: block;
        margin-top: 10px;
      }
    }
  }

  .Footer-1 {
    padding: 3rem 0;
    font-size: 1rem;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .Footer-2 {
    padding: 1.5rem 0;
    background: rgba(0, 0, 0, 0.3);
  }
}

.Header,
.Footer {
  nav {
    a {
      color: $text-color;
      font-weight: bold;
      margin-left: 1rem;
      transition: all 0.15s cubic-bezier(0.895, 0.03, 0.685, 0.22);

      @include media-breakpoint-up(lg) {
        margin-left: 2rem;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:hover {
        color: $primary-color;
      }

      &.active {
        color: $primary-color;
        position: relative;

        &.button-wrapper {
          &:after {
            content: none;
          }
        }

        &:after {
          content: "";
          width: 100%;
          height: 2px;
          border-radius: 1px;
          background: $primary-color;
          display: block;
          position: absolute;
          bottom: -5px;
          left: 0;
        }
      }
    }
  }
}

.LocaleSwitch {
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    margin-left: 1rem;
  }
}

.secondary-background {
  background: $secondary-color !important;
}

.secondary-light {
  background: $secondary-color-light !important;
}

.light {
  background: #ffffff !important;
}

.AppLayout {
  main {
    flex: 1 0 auto;
    background: $secondary-color;
    section {
      padding: 2rem 0;
      background: $secondary-color;

      @include media-breakpoint-up(md) {
        padding: 5rem 0;

        .section-title {
          display: flex;
          align-items: center;
        }
      }

      &.offgrid {
        position: relative;

        &:before,
        &:after {
          content: "";
          width: 15px;
          position: absolute;
          top: 0;
          bottom: 0;
          background: inherit;
        }

        &:before {
          left: -15px;
        }

        &:after {
          right: -15px;
        }
        @include media-breakpoint-up(md) {
          &:before,
          &:after {
            width: 3rem;
          }

          &:before {
            left: -3rem;
          }

          &:after {
            right: -3rem;
          }
        }
      }
    }
  }

  .PrivateLayout & {
    main {
      background: $secondary-color;
    }
  }
}

.Loader {
  background: $secondary-color;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-collapse {
  border: none;

  .ant-collapse-item {
    border: none;
    margin: 5px 0;

    &.ant-collapse-item-active {
      .ant-collapse-content {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }

  .ant-collapse-header {
    font-family: $headings-font;
    font-size: 1.2em;
    background: rgba(255, 255, 255, 0.3);
  }

  .ant-collapse-content {
    border: none;
  }
}

.ant-layout-content {
  position: relative;
}

.ant-progress {
  &-bg {
    height: 5px !important;
  }
  &-outer {
    display: flex;
  }
  &-line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
  }
}

.Form {
  .errors-wrapper {
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      &::first-letter {
        text-transform: uppercase;
      }

      &:after {
        content: "/";
        display: inline-block;
        margin: 0 10px;
      }

      &:last-of-type {
        &:after {
          content: none;
        }
      }
    }
  }
}

.upper-title {
  text-transform: uppercase;
}

.QuoteForm {
  .narative-sentences {
    color: $primary-color;
    margin-bottom: 1rem;

    @include media-breakpoint-up(sm) {
      margin-bottom: 1.9rem;
    }

    div {
      font-size: 1.2rem;
      line-height: 1.7em;

      @include media-breakpoint-up(sm) {
        font-size: 1.6rem;
      }
      display: inline-block;

      .ant-form-item {
        margin-bottom: 0;
      }

      .narative-input {
        margin: 0;
        padding: 0;
        width: auto;
        min-width: 80px;
        font-size: 1rem;
        border: none;
        //box-shadow: inset 0 -1px 0 0 rgba(38, 12, 178, 0.5);
        border-radius: 3px;
        transition: all 0.15s ease-in-out;
        color: $primary-color;
        line-height: 1.1rem;
        background: darken($secondary-color, 2%);
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 10px;

        &:focus {
          outline: none;
        }

        @include media-breakpoint-up(sm) {
          font-size: 1.5rem;
          line-height: 2.2rem;
          //height: 40px;
        }

        &.ant-select {
          position: relative;
          transition: none;
          z-index: 0;
          padding-right: 0;
          padding-left: 0;

          .ant-select-arrow {
            opacity: 1;
            transition: opacity 0.24s linear 0.1s;
            top: 6px;
            right: 0;
            bottom: 0;
            width: 22px;
            z-index: 2;
            position: absolute;
            align-items: center;
            display: flex;
            justify-content: center;
            font-size: 7px;
            color: $primary-color;
            background: darken($secondary-color, 3%);
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;

            // @include media-breakpoint-up(sm) {
            //   opacity: 0;
            //   background: darken($secondary-color, 3%);
            // }
          }

          &:hover {
            .ant-select-arrow {
              opacity: 1;
              color: $primary-color;
            }
          }
        }

        &:hover,
        &:focus,
        &.ant-input-number-focused {
          box-shadow: 0 0 0 1px $text-color;
          background: darken($secondary-color, 3%);
          //box-shadow: inset 0 -1px 0 0 $primary-color;

          .ant-select-arrow {
            background: darken($secondary-color, 5%);
          }
        }

        &::placeholder {
          opacity: 0;
        }

        &:focus {
          &::placeholder {
            opacity: 1;
          }
        }

        input::placeholder {
          color: rgba($text-color, 0.5);
        }

        .ant-select-selection {
          border: none;
          background: transparent;
          width: 100%;
        }
        .ant-select-open .ant-select-selection,
        .ant-select-focused .ant-select-selection,
        .ant-select-selection:focus {
          box-shadow: none;
        }

        .ant-input-number-handler-wrap {
          border-radius: 0;
          border-left: none;
          background: darken($secondary-color, 2%);
        }
        .ant-input-number-handler:active {
          background: darken($secondary-color, 3%);
        }
        .ant-input-number-handler-up {
        }
        .ant-input-number-handler-down {
          border-top: none;
        }

        .ant-input-number {
          line-height: 2rem;
        }

        .ant-input-number,
        .ant-input {
          background: transparent !important;
          border: none !important;

          &-focused {
            box-shadow: none;
          }
        }

        .ant-select-selection-selected-value {
          font-size: 1.2rem;

          @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
            line-height: 1.9em;
          }
        }

        .ant-input-suffix {
          font-size: 1.2rem;

          @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
          }
        }

        .ant-form-explain {
          font-size: 1rem;
          display: none;
        }
      }
    }
  }

  .error {
    font-size: 1rem;
    color: $error-color !important;
  }
}

.ant-select-dropdown {
  width: auto !important;
  min-width: 200px;

  .ant-select-dropdown-menu-item {
    font-size: 1.2rem;
    color: $primary-color;

    @include media-breakpoint-up(sm) {
      font-size: 1.125rem;
    }
  }
}

.frame {
  position: relative;
  background: $secondary-color-light;
  z-index: 1;

  &:before {
    content: "";
    top: 150px;
    left: -200%;
    right: -200%;
    bottom: 0;
    background: white;
    display: block;
    z-index: 0;
    position: absolute;
  }

  .frame-inner {
    background: $secondary-color-light;
    position: relative;
  }

  .frame-section {
    padding: 2rem 0;
    border-bottom: 1px solid rgba($text-color, 0.2);

    @include media-breakpoint-up(md) {
      padding: 2.5rem 0;
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  .frame-header {
    padding: 2.5em 4.5rem 0 4.5rem;
  }

  .frame-content {
    position: relative;
    //padding: 0 4.5rem 1rem 4.5rem;
    padding: 1.5rem;

    @include media-breakpoint-up(sm) {
      padding: 2rem;
    }

    @include media-breakpoint-up(md) {
      padding: 3rem 4.5rem;
    }
  }

  .frame-footer {
    padding: 2.5rem 1.5rem;
    background: $secondary-color;

    @include media-breakpoint-up(sm) {
      padding: 2.5rem 2rem;
    }

    @include media-breakpoint-up(md) {
      padding: 2.5rem 4.5rem;
    }
  }
}

.panel {
  background: #fff;
  padding: 2rem;
}

.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.clickable {
  cursor: pointer;
}

.center {
  margin: 0 auto;
}

.ant-drawer.drawer-sm > .ant-drawer-content-wrapper {
  max-width: 300px;
}

.ant-drawer.drawer-md > .ant-drawer-content-wrapper {
  max-width: 500px;
}

.ant-drawer.drawer-lg > .ant-drawer-content-wrapper {
  max-width: 700px;
}

.offers {
  margin-top: 2rem;
}

.check-item {
  display: flex;
  align-items: center;

  &:before {
    content: "";
    background: url("./../../images/check.svg");
    width: 25px;
    height: 22px;
    display: inline-block;
    margin-right: 10px;
  }
}

.SendVerificationCodeModal {
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
}

.ErrorMessage {
  font-size: 1rem;
  color: $error-color !important;
}

.Plan {
  height: 100%;
  display: flex;
  flex-direction: column;

  .banner {
    background: #fee0ba;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: bold;
    padding: 0.5rem 0;
    color: $primary-color;
    text-align: center;
    letter-spacing: 0.1em;
  }

  .ant-card {
    border-radius: 0;
    color: $primary-color;
    display: flex;
    flex: 1;

    .ant-card-body {
      display: flex;
      flex: 1;
      flex-direction: column;

      a {
        margin-top: auto;
      }
    }

    .lead ~ div {
      > p {
        font-size: 0.93rem;
        margin-bottom: 0.625rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}

.file-uploaded {
  .ant-list-item-meta-description {
    color: $primary-color;
  }

  .ant-avatar {
    border-radius: 0;

    img {
      width: auto;
      height: 100%;
    }
  }
}

.Result {
  background: #ffffff;
}

.hint-info {
  font-size: 0.9em;
  line-height: 1.5em;
  color: $primary-color;
  background: $secondary-color;
  width: 100%;
  padding: 10px;
}

p.primary {
  color: $primary-color !important;
}

p.text-small {
  font-size: 0.9rem;
}

p {
  a {
    text-decoration: underline;
  }
}

.no-break {
  white-space: nowrap;
}

.hint {
  line-height: 1.1em;
}

.ant-breadcrumb {
  padding: 0.5rem;
  border-radius: 3px;
  background: $secondary-color-light;
  margin-bottom: 2rem;

  .ant-breadcrumb-link {
    a {
      color: $primary-color;
    }
  }

  .ant-breadcrumb-separator {
    color: $primary-color;
  }
}

.ant-tag {
  text-transform: capitalize;
  &.large {
    padding: 0.3rem 0.8rem;
    font-size: 0.9rem;
  }
}

.panel {
  box-shadow: 0 2px 5px 0 rgba(#000000, 0.15);
}

.ant-table-wrapper {
  background: #ffffff;
  box-shadow: 0 2px 5px 0 rgba(#000000, 0.15);

  .ant-table-pagination {
    padding-right: 1rem;
  }
}

.blob {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  margin: 0 auto;
  background: $secondary-color-light;
  height: 100%;
  width: 100%;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: morph 6s linear infinite alternate;
  will-change: border-radius;
  z-index: 0;
}

@keyframes morph {
  0% {
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
  }
  100% {
    border-radius: 40% 60%;
  }
}

.ant-drawer {
  pre {
    font-size: 0.8rem;
  }
}

.ant-spin {
  .ant-spin-text {
    font-family: $headings-font;
    margin-top: 1rem;
    font-size: 1.5rem;
  }
}

.loader-wrapper {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;

  @include media-breakpoint-up(md) {
    padding-top: 8rem !important;
    padding-bottom: 8rem !important;
  }
}

.cookieConsent {
  left: 1rem !important;
  bottom: 1rem !important;
  right: 1rem !important;
  width: auto !important;
  padding: 1rem !important;

  > div {
    flex: 1 0 100% !important;
    margin: 0 !important;
  }

  button {
    margin-top: 1rem !important;
    margin-right: 1rem !important;

    &:last-of-type {
      margin-right: 0 !important;
    }
  }

  @include media-breakpoint-up(md) {
    left: 2rem !important;
    bottom: 2rem !important;
    right: auto !important;
    width: 100% !important;
    max-width: 400px !important;
  }
}

.ant-list-item-meta-title {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 1rem !important;
  color: $primary-color;
}

.medical-declaration-list .ant-list-item-meta-description {
  color: $text-color;
}

.ant-page-header-back {
  line-height: 2rem;

  i {
    color: $primary-color;
  }
}

.ant-descriptions-view {
  table {
    width: 100%;
    overflow-wrap: break-word;

    th,
    td {
      white-space: inherit;
    }
  }
}

.field-policyNumber {
  &:before {
    content: "#";
    margin-right: 0.2rem;
    color: $primary-color;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    align-items: center;
  }
}

.ant-descriptions-title {
  @extend h6;
}
