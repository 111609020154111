.ant-form-item-label > label::after {
  display: none;
}

.narative-submit {
  margin-top: 1.5rem;

  @include media-breakpoint-up(sm) {
    margin-top: 0;
  }
}

.ant-select-open {
  box-shadow: 0 0 0 1px $text-color;
}

.ant-select-dropdown {
  @include media-breakpoint-down(sm) {
    left: 1rem !important;
    right: 1rem !important;
  }
}

input.narative-input,
.ant-input,
.ant-select-selection--single,
//.ant-radio-group,
.ant-select-auto-complete.ant-select .ant-input,
.ant-select-auto-complete.ant-select .ant-select-selection--single {
  height: 2rem;
  line-height: 2rem !important;
  color: $primary-color;

  @include media-breakpoint-up(sm) {
    height: 2.5rem;
    line-height: 2.5rem;
  }
}

.ant-radio-group-solid {
  display: flex;
  width: 100%;

  @include media-breakpoint-up(sm) {
    display: block;
    width: auto;
  }
}

.ant-radio-button-wrapper {
  white-space: nowrap;
  text-align: center;
  flex: 1 0 auto;

  @include media-breakpoint-up(sm) {
    white-space: normal;
  }
}

.ant-radio-group {
  label {
    color: $primary-color;
  }

  &-small {
    label {
      height: auto !important;
      font-size: 0.9rem !important;
      padding: 0.5rem 0.5rem !important;
      line-height: 1rem !important;
    }
  }
}

.ant-checkbox {
  ~ span {
    line-height: 1.4em;
  }
}

.ant-select-selection-selected-value {
  @include media-breakpoint-up(sm) {
    line-height: 2.5rem;
  }
}

.ant-form-item-control {
  line-height: 1em;
}

.ant-switch {
  ~ p {
    color: $primary-color;
    font-size: 0.9rem;
  }
}

.ant-input::placeholder {
  color: lighten($text-color, 5%);
}

.ant-form-item-required::before {
  color: $error-color;
}

.ant-form-extra {
  color: $text-color;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

span.ant-radio {
  + * {
    padding-left: 1rem;
  }
  ~ span {
    white-space: initial;
    color: $primary-color;
    font-size: 0.9rem;
  }
}

.input-code {
  width: 100% !important;
  text-align: center;
  margin: 1rem 0;

  input {
    border-color: $text-color;
    background: $secondary-color-light;
    color: $primary-color;
    width: 50px !important;
    height: 50px !important;

    @include media-breakpoint-up(md) {
      width: 58px !important;
      height: 54px !important;
    }

    &:focus {
      border: 1px solid $primary-color;
      caret-color: $primary-color;
      background: $secondary-color;
    }

    &:nth-of-type(3) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-right: 1px solid $text-color;
      margin-right: 2rem;

      &:focus {
        + input {
          border-left: 1px solid $primary-color;
        }
      }
    }

    &:nth-of-type(4) {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-of-type {
      border-right: 1px solid $text-color;
    }
  }
}

.ant-upload {
  &.ant-upload-drag {
    background-color: $secondary-color;
    border: 1px dashed $primary-color;
  }
  .ant-upload-text {
    font-size: 0.9rem !important;
  }
}

.file-uploaded {
  .ant-list-item-meta-description {
    font-size: 0.9rem;
    line-height: 1.4em;
  }

  .ant-list-item-meta {
    align-items: center;
  }

  .ant-list-item-action {
    i {
      color: red;
    }
  }
}

.fileUpload {
  &.hasFile {
    .ant-upload {
      display: none;
    }
  }
}
