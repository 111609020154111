body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: $text-color;
  overflow-y: scroll;
}

.lead {
  line-height: 1.8em;

  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
  }
}

.hero-lead {
  font-size: 1.125rem;
  line-height: inherit;

  @include media-breakpoint-up(md) {
    font-size: 1.3rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headings-font;
}

h1,
.h1 {
  font-size: 2.25rem !important;

  @include media-breakpoint-up(sm) {
    font-size: 2.5rem !important;
  }

  @include media-breakpoint-up(md) {
    font-size: 3rem !important;
  }
}

h2,
.h2 {
  font-size: 1.75rem !important;

  @include media-breakpoint-up(sm) {
    font-size: 2rem !important;
  }

  @include media-breakpoint-up(md) {
    font-size: 2.5rem !important;
  }
}

h3,
.h3 {
  font-size: 1.5rem !important;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem !important;
  }

  @include media-breakpoint-up(md) {
    font-size: 2.25rem !important;
  }
}

h4,
.h4 {
  font-size: 1.25rem !important;

  @include media-breakpoint-up(sm) {
    font-size: 1.5rem !important;
  }

  @include media-breakpoint-up(md) {
    font-size: 2rem !important;
  }
}

h5,
.h5 {
  font-size: 1.125rem !important;

  @include media-breakpoint-up(md) {
    font-size: 1.5rem !important;
  }
}

h6,
.h6 {
  font-size: 1.125rem !important;
}
